import Link from "next/link";
import { Button, Divider } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Testemonial } from "../UI/components/Box/Testemonial";
import { EffectFade, Autoplay } from "swiper";
import { HeaderHome } from "../UI/components/Box/HeaderHome";
import { useTranslation } from "next-i18next";
import { WhatsAppWidget } from "react-whatsapp-widget";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";

import "react-whatsapp-widget/dist/index.css";
const OurMilestone = [
  {
    title: "How Does Coimex Work?",
    text: "Coimex, Chain of Importers & Exporters, is a global digital platform building a trustable community of traders, wholesalers, retailers and manufacturers through annual membership. The Platform connects you with trustable traders worldwide and builds long-term partnerships using the latest technology of mobile and web applications.",
    image: "/images/web-industry24.webp",
  },
  {
    title: "Coimex For Exporter",
    text: "Exporters enjoy the easiest and fastest way to showcase their products to trustable customers worldwide. Staying connected will empower the Exporters by giving them the opportunity to expand their businesses while creating awareness within the different regions of the seven continents.",
    image: "/images/web-industry32.webp",
  },
  {
    title: "Coimex For Importers",
    text: "Importers enjoy the advantages of being visible to the variety of category-leader suppliers worldwide. Staying connected will empower the Importers by catching the real-time opportunities of their categories and becoming competitive in their countries.",
    image: "/images/web-industry2.webp",
  },
  {
    title: "Benefits of Coimex Trading Model",
    text: "Coimex, integrates technology into the business world to enable safe trade for the actors of international trade. The transparent and informative structure of Coimex application empowers traders around the globe. The platform allows the ecosystem to be independent and free from outside intervention in order to maximize the credibility of Coimex members.",
    image: "/images/web-industry54.webp",
  },
];

export default function Home() {
  const { t } = useTranslation("common");
  const TestemonialData = [
    {
      author: t("testmonial.1"),
      title: t("testmonial.2"),
      text: t("testmonial.0"),
    },
    {
      author: t("testmonial.5"),
      title: t("testmonial.4"),
      text: t("testmonial.3"),
    },
    {
      author: t("testmonial.8"),
      title: t("testmonial.7"),
      text: t("testmonial.6"),
    },
    {
      author: t("testmonial.11"),
      title: t("testmonial.10"),
      text: t("testmonial.9"),
    },
  ];
  const OurMilestone = [
    {
      title: t("homePage.0"),
      text: t("homePage.1"),
      image: "/images/web-industry24.webp",
    },
    { title: t("homePage.2"), text: t("homePage.3"), image: "/images/1.jpg" },
    { title: t("homePage.4"), text: t("homePage.5"), image: "/images/2.jpg" },
    {
      title: t("homePage.6"),
      text: t("homePage.7"),
      image: "/images/web-industry54.webp",
    },
  ];
  const CoimexBadge = [
    {
      text: t("homePage.12"),
      image: "/images/ico1.svg",
      title: t("homePage.20"),
    },
    {
      text: t("homePage.13"),
      image: "/images/ico2.svg",
      title: t("homePage.21"),
    },
    {
      text: t("homePage.14"),
      image: "/images/ico3.svg",
      title: t("homePage.22"),
    },
  ];

  const DISABLED = true;
  return (
    <>
      <Head>
        <title>Coimex</title>
        <meta name="description" content={t("meta.0")} />
        <meta name="keywords" content={t("meta.0")} />
        <meta itemProp="description" content={t("meta.0")} />
        <meta property="og:description" content={t("meta.0")} />
      </Head>
      <div>
        <div className="bg-cover flex items-center justify-start bg-center bg-no-repeat sm:h-[650px] h-[360px] w-full sm:bg-[url('/images/Web-hero4.webp')] bg-[url('/images/hero-mobile12.png')]">
          <h1 className="text-PrimaryE3 text-center text-2xl sm:hidden flex font-MontserratSemiBold mx-auto self-end mb-8">
            {t("homePage.8")}
          </h1>
          <HeaderHome />
        </div>
        <div className="bg-graybackground sm:hidden px-9 pt-9 pb-7 justify-center items-center flex flex-col">
          <h2 className="font-MontserratRegular font-normal px-2 w-full text-base text-[#313033 ] leading-5">
            {t("homePage.18")}
          </h2>
          <a
            target="_blank"
            href="https://api.whatsapp.com/send/?phone=%2B905324479299&text&type=phone_number&app_absent=0"
            rel="noopener noreferrer"
            className=" bg-fontColorB  flex justify-center items-center w-full h-[42px] rounded-full text-white font-MontserratMedium text-base mt-5 "
          >
            <p className=" text-white font-MontserratMedium text-base">
              {t("homePage.10")}
            </p>
          </a>
          <div className="bg-graybackground border-2 w-full h-[42px] rounded-full border-fontColorB  mt-3">
            <Link
              href={"/exim-token"}
              className="border-2 w-full border-fontColorB  flex justify-center items-center h-full rounded-full  text-fontColorB font-MontserratMedium text-base"
            >
              <a
                rel="noopener noreferrer"
                className=" h-full   flex justify-center items-center rounded-full "
              >
                <p className=" text-fontColorB font-MontserratMedium text-base">
                  {t("homePage.11")}
                </p>
              </a>
            </Link>
            {/* <Button className=" border-2 w-full border-fontColorB h-full rounded-full  text-fontColorB font-MontserratMedium text-base">
            Join Now
            </Button> */}
          </div>
        </div>
        <div className="flex flex-col justify-center">
          {/* <div className=" w-11/12 m-auto my-4 md:hidden">
            <div className="rounded-xl  shadow-lg  flex leading-5 flex-col md:flex-row items-center md:max-h-[400px] bg-whiteLatic">
              <img
                src={"/images/hero.png"}
                className="rounded-xl  min-h-[180px] md:absolute max-h-[180px]  min-w-full"
                alt={""}
              />
              <h3 className="my-5 z-50 leading-8 font-extralight md:w-6/12 font-MontserratThin text-[#38466D] text-lg px-8">
                {t("homePage.19")}
              </h3>
            </div>
          </div>
          <div className="w-11/12 m-auto my-4 hidden md:block">
            <div className="rounded-xl bg-cover bg-center bg-no-repeat  h-[480px] bg-[url('/images/hero.png')]   flex leading-5 flex-col md:flex-row-reverse items-center max-h-[430px] bg-whiteLatic">
            
              <div className="md:w-6/12 rounded-r-xl px-9 bg-[#f4eff4b8] flex justify-center items-center h-full">
                <h3 className="my-4 z-50 leading-[44px]  font-MontserratRegular text-[#38466D] text-[32px] px-8">
                  {t("homePage.19")}
                </h3>
              </div>
            </div>
          </div> */}
          <div className="hidden md:flex m-auto md:justify-between justify-center w-full gap-y-2 items-center md:w-11/12 mt-2 flex-wrap">
            {CoimexBadge.map((item, i) => (
              <div
                key={i}
                className="w-[32%] shadow-md p-6 h-[326px] rounded-xl bg-whiteLatic items-center  flex flex-col"
              >
                <img src={item.image} alt={item.text} />
                <h3 className="text-center mt-6 font-MontserratMedium text-2xl text-[#38466D] font-medium">
                  {item.title}
                </h3>
                <p className="text-center mt-6  font-MontserratRegular text-base text-[#38466D]">
                  {item.text}
                </p>
              </div>
            ))}
          </div>
          <div className="flex md:hidden">
            <Swiper slidesPerView={"auto"} spaceBetween={10}>
              {CoimexBadge.map((item, i) => (
                <SwiperSlide
                  key={i}
                  className="rounded-xl p-3 py-4 flex leading-5 flex-col items-center h-[250px]  w-[299px] bg-whiteLatic first:mx-6"
                >
                  <h3 className=" text-base text-[#38466D] font-MontserratMedium">
                    {item.title}
                  </h3>
                  <div className="mt-2 bg-[#38466D] h-[1px] w-10/12" />
                  <h4 className="mt-3 text-center leading-5 px-6 font-MontserratRegular text-[#38466D] text-xs">
                    {item.text}
                  </h4>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="md:hidden">
            <h2 className="w-11/12 m-auto mt-4 text-balck flex md:hidden text-base font-MontserratSemiBold px-2">
              TESTIMONIAL
            </h2>
            <Swiper
              spaceBetween={30}
              effect={"fade"}
              autoplay={{
                delay: 10500,
                disableOnInteraction: false,
              }}
              modules={[EffectFade, Autoplay]}
              className="w-11/12 mt-4 mb-8"
            >
              {TestemonialData.map((item, index) => (
                <SwiperSlide
                  key={index}
                  className="bg-whiteLatic px-8 py-10 rounded-xl m-auto  w-11/12 md:h-[214px] h-[360px]"
                >
                  <Testemonial item={item} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="ml-4 flex md:hidden">
            <Swiper slidesPerView={"auto"} spaceBetween={10}>
              {OurMilestone.map((item, index) => (
                <SwiperSlide
                  key={index}
                  className="rounded-xl p-3 mb-5 flex leading-5 mt-2 flex-col items-center max-h-[410px]  w-[299px] bg-whiteLatic"
                >
                  <img
                    src={item.image}
                    className="rounded-xl min-h-[140px] max-h-[140px] min-w-full"
                    alt={item.title}
                  />
                  <h2 className="mt-4  self-start text-base text-black font-MontserratMedium">
                    {item.title}
                  </h2>
                  <h3 className="mt-4 leading-5 font-MontserratRegular pb-3 text-black text-xs">
                    {item.text}
                  </h3>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className="w-11/12 flex flex-wrap m-auto justify-between ">
            {OurMilestone.map((item, index) => (
              <div
                key={index}
                className="rounded-xl 4k:max-w-4xl  md:w-[49%] p-3 md:flex leading-5 mt-4 flex-col items-center min-h-[430px]  hidden  bg-whiteLatic"
              >
                <img
                  width="100%"
                  height="100%"
                  src={item.image}
                  className="rounded-xl h-[380px]"
                  alt={item.title}
                />
                <h2 className="mt-10  self-start text-base text-black font-MontserratMedium">
                  {item.title}
                </h2>
                <h3 className="mt-4 pb-10 leading-5 font-MontserratRegular text-black text-[85%]">
                  {item.text}
                </h3>
              </div>
            ))}
          </div>
        </div>
        <div className="md:block hidden">
          <Swiper
            spaceBetween={30}
            effect={"fade"}
            autoplay={{
              delay: 10500,
              disableOnInteraction: false,
            }}
            modules={[EffectFade, Autoplay]}
            className="w-11/12 mt-4 mb-8"
          >
            {TestemonialData.map((item, index) => (
              <SwiperSlide
                key={index}
                className="bg-whiteLatic px-8 py-10 rounded-xl m-auto  w-11/12 md:h-[214px] h-[360px]"
              >
                <Testemonial item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
}
